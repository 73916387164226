<template>
	<div>
		<a-modal title="选择充值套餐" :footer="null" :width="480" :visible="visible"  @cancel="handleCancel" >
			
			<a-spin :spinning="loading">
				<div v-if="rechargeAct == false">
					<div class="flex wrap">
						<template v-for="(item,index) in datas">
							<div class="choose-set-meal-item active" v-if="select_id==index">
								<div class="flex alcenter center" style="height: 100%;">
									<div>
										<div class="text-center ft14 ftw500 cl-theme">{{item.num}}条</div>
										<div class="text-center ft12 ftw500 cl-theme">{{item.price}}元</div>
									</div>
								</div>
							</div>
							
							<div class="choose-set-meal-item" v-else @click="checkIt(index)">
								<div class="flex alcenter center" style="height: 100%;">
									<div>
										<div class="text-center ft14 ftw500 cl-info">{{item.num}}条</div>
										<div class="text-center ft12 ftw500 cl-notice">{{item.price}}元</div>
									</div>
								</div>
							</div>
						</template>
					</div>
					
					<div class="mt20 flex center">
				
						<a-button  key="submit" type="primary" @click="onSubmit">
							确定充值
						</a-button>
					</div>
			
				</div>
				
				<div v-if="rechargeAct == true">
					
					<div class="flex center mt24">
						<div class="recharge_type">
							<div class="flex center alcenter" style="height: 100%;">
								<i class="iconfont iconwepay ft18 " style="color: #4AB728;"/>
								<div class="ml8 ft14 ftw500 cl-black">微信支付</div>
							</div>
						</div>
					</div>
					
					<div class="mt24">
						<div class="flex center">
							<vue-qr  :text="codeUrl" :size="200"></vue-qr>
						</div>
						<div class="flex center mt16">
							<div class="ft12 ftwblod text-center cl-info">使用微信扫码支付</div>
						</div>
					</div>
				</div>
				
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import vueQr from 'vue-qr';
	export default{
		components: {
		  vueQr
		},
		
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
		},
		data(){
			return {
				loading: false,
				confirmLoading:false,
				rechargeAct:false,
				select_id:0,
				datas:[
					
				],
				orderId:0,
				codeUrl:'',
				timer:null,
			}
		},
		created(){
			this.loaddata();
		},
		destroyed(){
			this.clearTimer();
		},
		methods: {
			loaddata(){
				this.loading = true;
				this.$http.api('admin/getSmsPackage').then(res=>{
				    this.loading = false;
					this.datas = res.price;
				}).catch(res=>{
					this.loading = false;
				});
			},
			checkIt(index){
				this.select_id=index;
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.clearTimer();
				this.$emit("cancel");
			},
			clearTimer(){
				if(this.timer != null){
					clearInterval(this.timer);
					this.timer = null;
				}
			},
			checkTimer(){
				this.timer = setInterval(()=>{
					this.$http.papi('platform/paymentStatus',{
						order_id:this.orderId
					}).then(res=>{
						if(res.status == 1){
							this.$message.success('支付成功');
							this.handleCancel();
							this.refreshPage('/setting/sms'); //返回首页
						}
					}).catch(res=>{
						console.log(res);
					});
				},2000);
			},
			onSubmit(){
				this.loading = true;
				this.$http.papi('platform/smsOrder',{
					skuIndex:this.select_id,
				}).then(res=>{
					this.loading = false;
					this.rechargeAct=true;
					this.orderId = res.order_id;
					this.codeUrl = res.code_url;
					this.checkTimer();
				}).catch(res=>{
					this.loading = false;
				});
				
			},
			
		
			
		}
		
	}
</script>

<style>
	.choose-set-meal-item{
		width: 32%;
		height: 62px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #EBEDF5;
		margin-right: 1%;
		margin-bottom: 1%;
		cursor: pointer;
	}
	
	.choose-set-meal-item.active{
		border: 1px solid #4772FF;
	}
	
	.recharge_type{
		width: 124px;
		height: 48px;
		background: #FFFFFF;
		border-radius: 24px;
		border: 1px solid #EBEDF5;
	}
</style>