<template>
	<div>
		<div class="pd30">
			<div class="wxb-table-gray">
				<a-table rowKey="sms_send_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					
				</a-table>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../../../common/mixin/list.js';
	export default {
		mixins: [listMixin],
		data() {
			return {
				loading: false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [{
						title: '发送时间',
						dataIndex: 'add_time_format',
						align: 'center',
						ellipsis: true
					},
					{
						title: '发送类型',
						dataIndex: 'send_type',
						align: 'center',
						ellipsis: true
					},
					{
						title: '用户手机号',
						dataIndex: 'mobile',
						align: 'center',
						ellipsis: true
					},
					{
						title: '剩余短信数',
						dataIndex: 'after_sms_num',
						align: 'center',
						ellipsis: true
					},
					
				],
				datas: [
					
				],
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getSmsSendLogs',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
</style>
